<template>
    <div>
        <Row :gutter="8">
            <i-col span="4">
              <i-select placeholder="罚款类型" clearable v-model="query.feeType" size="small">
                <i-option value="52">供应商罚单</i-option>
                <i-option value="53">代理商罚单</i-option>
              </i-select>
            </i-col>
            <i-col span="4">
              <i-select placeholder="审批状态" clearable v-model="query.status" size="small">
                <i-option value="0">草稿</i-option>
                <i-option value="4">待审批</i-option>
                <i-option value="5">已通过</i-option>
                <i-option value="6">已拒绝</i-option>
                <i-option value="-1">废弃</i-option>
              </i-select>
            </i-col>
            <i-col span="6">
              <i-input placeholder="关键字：审批名称模糊查询" v-model="query.keyword" size="small"></i-input>
            </i-col>
            <i-col span="6">
              <i-button size="small" icon="ios-search" type="primary" @click="handleSearch">搜索</i-button>
              <i-button v-if="!isManage"  @click="CreateApproval" type="success"  size="small" class="m-l-5">创建新审批</i-button>
            </i-col>
        </Row>

        <Table :columns="fineColumns" :data="fineData" stripe></Table>
        <div class="paging_style">
          <Page size="small" :total="total" show-total show-elevator  :current="query.pageNumber" :page-size="query.pageSize" @on-change="handleChangePage"></Page>
        </div>

        <Modal v-model="showModal" width="400">
          <p slot="header" class="workplatform-title">
            <span v-if="isCancel">撤回审批</span>
            <span v-else>作废审批</span>
          </p>
          <div >
            <p v-if="isCancel">您确认要撤回该审批任务？</p>
            <p v-else>您确认要作废该审批任务？此操作无法撤回</p>
            <i-input v-model="description"  type="textarea" class="m-t-10" placeholder="请输入审批备注"></i-input>
          </div>
          <div slot="footer">
            <i-button type="primary" size="large" @click="sumitCancelApproval">确认</i-button>
          </div>
        </Modal>
    </div>
</template>

<script>
import { getPenaltyPage, deletePenalty, invalidPenalty, terminatePenaltyApproval } from '@/api/statement/penalty'
import { formatSpecialApprovalStatus } from '@/utils/tagStatus'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    isManage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fineColumns: [
        { title: '名称', key: 'title' },
        {
          title: '罚款类型',
          render: (h, data) => {
            if (data.row.feeType === 52) {
              return h('tag', { props: { color: 'blue', type: 'border' } }, '供应商罚单')
            } else {
              return h('tag', { props: { color: 'green', type: 'border' } }, '代理商罚单')
            }
          }
        },
        {
          title: '罚款金额(元)',
          render: (h, data) => {
            return h('span', toMoney(data.row.amount))
          }
        },
        { title: '审批内容', key: 'content', ellipsis: true },
        {
          title: '状态',
          width: 120,
          align: 'center',
          render: (h, data) => {
            return formatSpecialApprovalStatus(h, data.row.status, data.row.statusName)
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const detailButton = h('a', {
              style: { 'margin-right': '5px' },
              on: {
                click: () => {
                  this.showDetails(params)
                }
              }
            }, '详情')
            const editButton = h('a', {
              style: { 'margin-right': '5px' },
              on: {
                click: () => {
                  this.$store.commit('set_approvalCreate_detailId', params.row.id)
                }
              }
            }, '编辑')
            const deleteButton = h('a', {
              style: { 'margin-right': '5px', color: '#ef4f4f' },
              on: {
                click: () => {
                  this.showDelete(params)
                }
              }
            }, '删除')

            const cancelButton = h('a', {
              style: { 'margin-right': '5px', color: '#ef4f4f' },
              on: {
                click: () => {
                  this.isCancel = true
                  this.description = ''
                  this.penaltyId = params.row.id
                  this.showModal = true
                }
              }
            }, '撤回')

            const invalidButton = h('a', {
              style: { 'margin-right': '5px', color: '#ef4f4f' },
              on: {
                click: () => {
                  this.isCancel = false
                  this.description = ''
                  this.penaltyId = params.row.id
                  this.showModal = true
                }
              }
            }, '作废')

            const buttonList = [detailButton]

            if (params.row.status === 5) {
              buttonList.push(invalidButton)
            } else if (params.row.status === 4) { // 仅审批可撤回
              buttonList.push(cancelButton)
            } else if (params.row.status === 0) { // 仅草稿可删除
              buttonList.push(editButton)
              buttonList.push(deleteButton)
            } else if (params.row.status === 6) { // 已拒绝可编辑
              buttonList.push(editButton)
            }
            // }

            return h('div', buttonList)
          }
        }
      ],
      fineData: [],
      total: 0,
      query: {
        feeType: '',
        publisherId: this.$store.getters.token.userInfo.publisherId,
        agentCompanyId: this.$store.getters.token.userInfo.companyId,
        keyword: '',
        maxPenaltyDate: '',
        minPenaltyDate: '',
        pageNumber: 1,
        pageSize: 15,
        status: '',
        supplierCompanyId: ''
      },
      showModal: false,
      description: '',
      penaltyId: 0,
      isCancel: false,
      userId: this.$store.getters.token.userInfo.userId
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      getPenaltyPage(this.query).then(res => {
        this.fineData = res.list
        this.total = res.totalRow
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    handleChangePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    CreateApproval () {
      this.$store.commit('set_approvalCreate_detailId', null)
    },
    showDetails (params) {
      this.$store.commit('set_approvalCreate_pageType', 'detail')
      this.$store.commit('set_approvalCreate_detailId', params.row.id)
    },
    showDelete (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认要删除该罚款审批吗？',
        onOk: () => {
          deletePenalty({ penaltyId: params.row.id }).then(
            res => {
              if (res && !res.errcode) {
                this.$Notice.success({ desc: '罚款审批删除成功', title: '操作成功' })
                this.initPageData()
              }
            }
          ).catch(() => {
            this.$Modal.remove()
          })
        }
      })
    },
    sumitCancelApproval () {
      const postData = {
        penaltyId: this.penaltyId,
        invalidMark: this.description,
        terminateMark: this.description
      }

      if (this.isCancel) {
        terminatePenaltyApproval(postData).then(res => {
          if (res && !res.errcode) {
            this.showModal = false
            this.$Notice.success({ desc: '撤销审批成功！' })
            this.initPageData()
          }
        })
      } else {
        invalidPenalty(postData).then(res => {
          if (res && !res.errcode) {
            this.showModal = false
            this.$Notice.success({ desc: '作废罚款审批成功！' })
            this.initPageData()
          }
        })
      }
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.approvalCreate.beginUpdate
    }
  },
  watch: {
    beginUpdate () {
      this.handleSearch()
    }
  }
}
</script>
